import asyncComponent from '../hoc/asyncComponent';

const AdvancedSearch = asyncComponent(() => import('../views/AdvancedSearch/Listing'));
const ContactDetails = asyncComponent(() => import('../views/Contact/Details'));
const AddressableMarket = asyncComponent(() => import('../views/AddressableMarket/MarketDetails'));
const MyList = asyncComponent(() => import('../views/MyList/Listing'));
const UnsubList = asyncComponent(() => import('../views/Unsubscribe/Listing'));
const SuppressionList = asyncComponent(() => import('../views/Suppression/Listing'));
const NotFound = asyncComponent(() => import('../components/Error404/index'));
const AddressableMarketFNN = asyncComponent(() => import('../views/AddressableMarket/DetailsFNN'));
const AddressableMarketMobility = asyncComponent(() => import('../views/AddressableMarket/DetailsMobility'));
const AddressableMarketEnergy = asyncComponent(() => import('../views/AddressableMarket/DetailsEnergy'));
const AddressableMarketList = asyncComponent(() => import('../views/AddressableMarket/List'));
const CampaignList = asyncComponent(() => import('../views/Campaign/List'));
const SlotList = asyncComponent(() => import('../views/Campaign/Slot/Listing'));
const UnsubscribedUserList = asyncComponent(()=> import('../views/UnsubscribeManagement/UnsubscribedUserList'))
const DncList = asyncComponent(()=> import('../views/DncList/DncList'))
const UnsubscribeReport = asyncComponent(()=> import('../views/UnsubscribeReport/UnsubscribeReport'))
const Dashboard = asyncComponent(() => import('../views/Dashboard/index'));
const AddCampaign = asyncComponent(() => import('../views/Campaign/AddCampaign'))
const MauticCampaignReport = asyncComponent(() => import('../views/Campaign/MauticCampaignReport'))
const SaveSearch = asyncComponent(() => import('../views/AdvancedSearch/SaveSearch'))

const allRoute = [
  // { path: '/', exact: true, name: 'Route' },
  { path: '/app/my-list', name: 'My List', component: MyList },
  { path: '/app/saved-searches', name: 'Saved Search', component: SaveSearch },
  { path: '/app/advanced-search/:category?/:keyword?', name: 'My List', component: AdvancedSearch },
  { path: '/app/contact-details/:contactid?', name: 'Contact Details', component: ContactDetails },
  { path: '/app/unsubscribe-list', name: 'Unsubscribe List', component: UnsubList },
  { path: '/app/addressable-market/fnn', name: 'Addressable Market', component: AddressableMarketFNN },
  { path: '/app/addressable-market/mobility', name: 'Addressable Market', component: AddressableMarketMobility },
  { path: '/app/addressable-market/energy', name: 'Addressable Market', component: AddressableMarketEnergy },
  { path: '/app/addressable-market-list', name: 'Addressable Market', component: AddressableMarketList },
  { path: '/app/addressable-market/:market_id?', name: 'Addressable Market', component: AddressableMarket },
  { path: '/app/suppression-list', name: 'Suppression List', component: SuppressionList },
  { path: '/app/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/app/campaign-list', name: 'Campaigns', component: CampaignList },
  { path: '/app/campaign/slot-list', name: 'Campaigns', component: SlotList },
  { path: '/app/campaign/add-campaign', name: 'Campaigns', component: AddCampaign },
  { path: '/app/campaign/unsubscribe-user-report', name: 'Reports', component: UnsubscribedUserList },
  { path: '/app/reports/mautic-campaign-report/:instance?', name: 'Mautic Reports', component: MauticCampaignReport },
  { path: '/app/dnc-list', name: 'Reports', component: DncList },
  { path: '/app/unsubscribe-report', name :'Reports', component:UnsubscribeReport},
  { path: '/app/saved-searches', exact: true, name: 'Saved Searches', component: SaveSearch },
  { path: '', exact: true, name: 'Error 404', component: NotFound },
];
const routeNavigation = [
  {
    parent: "Dashboard",
    parentIcon: 'zmdi zmdi-tv-list zmdi-hc-fw',
    path: '/app/dashboard'
  },
  {
    parent: 'Advance Search',
    parentIcon: 'zmdi zmdi-zoom-in zmdi-hc-fw',
    childNav: [
      {
        child: "Leads/Account Search",
        path: "/app/advanced-search"
      },
      {
        child: "Saved Search List",
        path: "/app/saved-searches"
      }
    ]
  },
  {
    parent: 'My List',
    parentIcon: 'zmdi zmdi-accounts-list-alt zmdi-hc-fw',
    childNav: [
      {
        child: "Manage List",
        path: "/app/my-list"
      }
    ]
  },
  {
    parent: "Campaigns",
    parentIcon: 'zmdi zmdi-calendar zmdi-hc-fw',
    childNav: [
      {
        child: 'Campaign List',
        path: '/app/campaign-list'
      },
      {
        child: 'Slot Management',
        path: '/app/campaign/slot-list'
      },
    ]
  },
  // TODO : Functionality is yet to be implemented		
  {
    parent: 'Masters',
    parentIcon: 'zmdi zmdi-book zmdi-hc-fw',
    childNav: [
      // TODO : Functionality is yet to be implemented	
      // {
      //   child: "Region Master",
      //   path: "/app/contact-allocate"
      // },
      // {
      //   child: "Industry Master",
      //   path: "/app/contact-deallocate"
      // }
      ,
      {
        child: "Unsubscribe List",
        path: "/app/unsubscribe-list"
      }
      ,
      {
        child: "Suppression List",
        path: "/app/suppression-list"
      },
      {
        child: 'Campaign Master',
        path: '/app/campaign/add-campaign'
      }
    ]
  },
  {
    parent: "Addressable Market",
    parentIcon: 'zmdi  zmdi-collection-text zmdi-hc-fw',
    path: '/app/addressable-market-list'
  },
  //MIS REPORT
  {
    parent: 'Reports',
    parentIcon: 'zmdi zmdi-chart zmdi-hc-fw',
    childNav: [
      {
        child: "Unsubscribe Report",
        path: "/app/unsubscribe-report"
      }
      // {
      //   child: "Campaign Report",
      //   path: "/app/campaign-report"
      // }
    ]
  },
  {
    parent: 'Mautic Reports',
    parentIcon: 'zmdi zmdi-chart zmdi-hc-fw',
    childNav: [
      {
        child: "Webinar Futurebridge",
        path: "/app/reports/mautic-campaign-report/1"
      },
      {
        child: "Insidertrends Futurebridge",
        path: "/app/reports/mautic-campaign-report/2"
      },
      {
        child: "Clabs Cognition-solutions",
        path: "/app/reports/mautic-campaign-report/3"
      },
      {
        child: "CS3 Cognition-solutions",
        path: "/app/reports/mautic-campaign-report/4"
      }
    ]
  },											
]
export { allRoute, routeNavigation };
